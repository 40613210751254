<template>
  <div id="wallet_app_div">
      <van-nav-bar
        left-text="返回"
        left-arrow
        @click-left="$router.replace(indexUrl)"/>
      <div style="padding:10px;background-color:white;">
        <van-cell-group style="padding:10px 0px;">
            <van-cell @click="$router.push('/bank/balance')"  is-link >
                <template #title>
                    <van-icon name="balance-pay" color="#efc086" size="20" style="vertical-align: -21%" />
                    &nbsp;
                    <span class="custom-title">余额</span>
                </template>
                <template #default>
                    <span style="color:black;">￥{{userBalance}}</span>
                </template>
            </van-cell>
            <van-cell @click="$router.push('/userBill')" is-link >
                <template #title>
                    <van-icon name="after-sale" color="#efc086" size="20" style="vertical-align: -21%" />
                    &nbsp;
                    <span class="custom-title">账单</span>
                </template>
            </van-cell>
            <van-cell @click="$router.push('/bank')" is-link >
                <template #title>
                    <van-icon name="credit-pay" color="#4e72b8" size="20" style="vertical-align: -21%" />
                    &nbsp;
                    <span class="custom-title">银行卡</span>
                </template>
                <template #default>
                    <span>去绑定</span>
                </template>
            </van-cell>
        </van-cell-group>
      </div>
  </div>
</template>

<script>
import { CellGroup,Cell,Icon,Divider,NavBar,Dialog  } from 'vant';
import {allinpayUser} from '../../store/allinpay'
import {GetAllInPayUserInfo,SignContract,getAccountBalance} from '../../api/common'

export default {
    components:{
        [CellGroup.name]: CellGroup,
        [Cell.name]: Cell,
        [Icon.name]: Icon,
        [Divider.name]: Divider,
        [NavBar.name]: NavBar,
    },
    data(){
        return {
            userBalance:'',
            indexUrl:'/mindex',
        }
    },    
    destroyed(){
        document.body.style.backgroundColor = 'white'
    },
    mounted(){
        document.body.style.backgroundColor = 'rgb(226, 226, 226)'
    },
    methods:{
        targetPage(res,userId){
            var user = allinpayUser.get({userId:userId});
            if(res.allinPayUserStats===0){
                this.$router.push('/bank/auth')
                return
            }else if(res.allinPayUserStats===7 && res.memberType===2){
                this.$router.replace({path:'/bank/companyAuth'})
            }else if(res.allinPayUserStats ===1 && res.memberType === 2){
                if(!res.allinPayPhome){
                    this.$router.replace({path:'/bank/bindPhone'})
                    return
                }
                this.$dialog.alert({
                    title: '提示',
                    message: '当前企业用户已认证成功！',
                    theme: 'round-button',
                }).then(() => {
                    this.$router.go(-1);
                });
            }else{
                if(!res.allinPayPhome){
                    this.$router.replace({path:'/bank/bindPhone'})
                }else if(!res.idCard){
                    this.$router.replace({path:'/bank/realName'})
                }else if(res.bindBankCard&&!res.hasSign){
                    var source = this.$util.isMobile();
                    var href = window.location.href;
                    var signbody = {
                        "userId":user.userId,
                        "userSource": user.userSource,
                        "jumpPageType": 1,
                        "backUrl":process.env.VUE_APP_PAY_NOTIFY_URL,
                        "jumpUrl":href,
                        "noContractUrl": href,
                        "source": source?1:2
                    };
                    this.$toast.loading({duration: 0,forbidClick: true,message: '获取电子合约'});
                    SignContract(signbody).then(res=>{
                        if(res.status){
                            window.location.href=res.data.url;
                        }else{
                            this.$toast.clear();
                            this.$toast.fail("请求电子合约失败！");
                        }
                    }).catch(()=>{
                        this.$toast.clear();
                    });
                }else if(!res.bindBankCard){
                    var flag = window.localStorage.getItem('bankTipsFlag');
                    if(!flag){
                        Dialog.confirm({
                            title: '提示',
                            message: '绑定银行卡后才能提现，是否继续绑定银行卡？',
                        })
                        .then(() => {
                            window.localStorage.setItem('bankTipsFlag',1);
                            this.$router.push({path:'/bank/add'})
                        })
                        .catch(() => {
                            window.localStorage.setItem('bankTipsFlag',1);
                        });
                    }
                }
            }
        },
        getAccount(user){
            var body = {
                userId:user.userId,
                userSource:user.userSource
            }
            getAccountBalance(body).then(res=>{
                if(res.status){
                    this.userBalance =parseFloat(res.data).toFixed(2);
                }else{
                    this.userBalance = 0.00;
                }
            }).catch(err=>{
                console.error(err);
            })
        }
    },
    created(){
        var sysUser = allinpayUser.get();
        GetAllInPayUserInfo({userId:sysUser.userId,userSource:sysUser.userSource}).then(res=>{
            var user = allinpayUser.update(res);
            if(user.userSource ==1 ){
                this.indexUrl = "/mindex"
            }else{
                this.indexUrl = "/personalIndex"
            }
            this.getAccount(user);
            this.targetPage(res,user.id);
        });
    }
}
</script>

<style>

</style>